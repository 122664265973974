<template>
	<div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="overflow-y: scroll; background:var(--light-bg-gray);">
		<div>
			<BHLoading :show="loading" />
			<template v-if="reservation && reservation.id">
				<div class="mb-3 dF aC jSB">
					<router-link to="/reservations" class="reservation-title"
						style="cursor: pointer; display: inline-block;">
						<div class="dF jSB aC w-full">
							<div>
								<a-icon class="mr-3" style="font-size: 1.75rem;" type="arrow-left" />
								<h3 style="display: inline-block;">
									Back to all Reservations</h3>
								<br />
								<h5 class="ml-5" style="display: inline-block;">{{ reservationName }} </h5>
							</div>
						</div>
					</router-link>
					<div class="dF aC" style="gap: 20px">
						<div class="bg-danger text-white rounded dF aC" v-if="reservation.status === 'void'">VOIDED
						</div>
						<div class="dF aC cursor-pointer" v-else @click="fetchReservationDetails">
							<a-tooltip overlayClassName="change-tooltip-color">
								<template slot="title">Refresh</template>
								<a-icon type="sync" style="font-size: 20px; color: var(--primary);" />
							</a-tooltip>
						</div>
					</div>
				</div>
				<a-tabs class="reservationTabs hide-scrollbar" v-model="currentTab" style="overflow-y:scroll">
					<a-tab-pane key="details" tab="Details">
						<detailsTab />
					</a-tab-pane>
					<a-tab-pane key="purchasers" tab="Purchasers & Reps">
						<purchasersTab @updateReservation="generatePreview" />
					</a-tab-pane>
					<a-tab-pane key="docs" tab="Documents">
						<documentsTab />
					</a-tab-pane>
					<a-tab-pane key="participants" tab="Internal Participants">
						<participantsTab @updateReservation="generatePreview" />
					</a-tab-pane>
					<a-tab-pane key="notes" tab="Notes">
						<notesTab />
					</a-tab-pane>
					<a-tab-pane key="timeline" tab="Timeline">
						<timelineTab />
					</a-tab-pane>
				</a-tabs>
			</template>
		</div>
	</div>
</template>

<script>
import BHLoading from 'bh-mod/components/common/Loading'
import detailsTab from '@/components/reservationTabs/detailsTab'
import documentsTab from '@/components/reservationTabs/documentsTab'
import notesTab from '@/components/reservationTabs/notesTab'
import participantsTab from '@/components/reservationTabs/participantsTab'
import timelineTab from '@/components/reservationTabs/timelineTab'
import purchasersTab from '@/components/reservationTabs/purchasersTab'
import { generateContractPreview, isProductAvailable, getContractTemplateName } from '@/components/steps/tools'

export default {
	components: {
		BHLoading, detailsTab, documentsTab, notesTab, participantsTab, timelineTab, purchasersTab
	},

	data() {
		return {
			loading: false,
			currentTab: "docs",
			envelopeFileKey: null,
		}
	},

	computed: {
		instance() {
			return this.$store.state.instance
		},

		reservation() {
			return this.$store.state.appData.currentReservation
		},

		reservationName() {
			if (this.instance.productType == 'highrise') {
				let { unit = {} } = this.reservation && this.reservation.product || {};
				let name = `Unit ${unit.unitNumber}`

				if (this.reservation.purchasers && this.reservation.purchasers.length) {
					if (this.reservation.purchasers.length === 1) name += ` - (Buyer: ${this.reservation.purchasers[0].firstName} ${this.reservation.purchasers[0].lastName})`
					else name += ` - ${this.reservation.purchasers.length} Purchasers`
				}

				return name
			} else {
				let { lot = {} } = this.reservation && this.reservation.product || {}
				let name = `Lot ${lot.name}${lot.block ? ` (Block ${lot.block})` : ''}`

				if (this.reservation.purchasers && this.reservation.purchasers.length) {
					if (this.reservation.purchasers.length === 1) name += ` - (Buyer: ${this.reservation.purchasers[0].firstName} ${this.reservation.purchasers[0].lastName})`
					else name += ` - ${this.reservation.purchasers.length} Purchasers`
				}
				return name
			}
		},

		currentContract() {
			if (!this.reservation.template) return {}
			return this.$store.state.appData.templates[this.reservation.template] || {}
		},
	},

	methods: {
		async fetchReservationDetails() {
			this.loading = true;
			try {
				let { data } = await this.$api.get(`reservations/:instance/${this.$route.params.id}`);
				if (data && data.statue === 'submitted') {
					this.$message.error('You are not allowed to view this reservation. Please contact the administrator.');
					this.$router.push(`/reservations`)
					return;
				}
				this.$store.commit('SET_CURRENT_RESERVATION', data)
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while fetching reservation details. Please try again.`))
				}
			}
			this.loading = false;
		},

		async generatePreview() {
			const reservation = this.reservation
			this.$store.commit('LOAD', true)

			try {
				const { key } = await generateContractPreview(reservation, this)
				this.envelopeFileKey = key
				this.updateReservation();
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, 'An error occurred while generating your contract. Please try again.'))
				}
			}
			this.$store.commit('LOAD', false)
		},

		updateReservation() {
			this.$store.commit('LOAD', true)
			let reservation = JSON.parse(JSON.stringify(this.reservation))

            const signers = [...this.signRoutePreview];

            if (this.requiresRepReview) {
                signers.splice(0, 1);
            }

			reservation.envelope = {
				name: getContractTemplateName(reservation, this.instance.productType) || this.currentContract.name,
				file: {
					template: this.currentContract.id,
					key: this.envelopeFileKey
				},
				signers: signers,
				requiresRepReview: this.requiresRepReview
			}

			let url = '/reservations/:instance'
			if (this.$route.params.id && reservation.id) {
				reservation.resend = true
				url = `/reservations/:instance/${reservation.id}`

				if (reservation.envelopes) {
					let mainEnvelope = reservation.envelopes.find(x => x.main)
					if (mainEnvelope) {
						reservation.envelope.id = mainEnvelope.id
						delete reservation.envelopes
					}
				}
			}

			if (this.instance.productType === 'lowrise') {
				reservation.lot = reservation.product.lot.id
				reservation.unit = reservation.product.unit.id
			} else {
				reservation.floor = reservation.product.floor.id
				reservation.unit = reservation.product.unit.id
			}

			this.$api[this.$route.params.id && reservation.id ? 'put' : 'post'](url, reservation)
				.then((resp) => {
					if (resp && resp.data && resp.data.id) {
						this.fetchReservationDetails();
						this.$message.success('Updated document sent for signing')
					}
				})
				.catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err, `An error occurred while creating your reservation. Please try again.`))
					}
				})
				.finally(() => {
					this.$store.commit('LOAD', false)
				})
		}
	},

	created() {
		this.fetchReservationDetails();
	},

}

</script>

<style lang="scss" scoped>
.reservation-title h3 {
	color: black;
	transition: color 0.2s ease-in-out;
}

.reservation-title:hover h3 {
	color: var(--orange) !important;
	transition: color 0.2s ease-in-out;
}
</style>

<style lang="scss">
.reservationTabs .ant-tabs-nav {
	font-size: 18px;

	.ant-tabs-tab {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.reservationTabs .ant-tabs-bar {
	margin: 0 0 25px 0;
}
</style>

